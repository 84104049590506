// Vue JS本体モジュールの読み込み
import Vue from 'vue';
import vuetify from './plugin/vuetify';
import router from "./routes/indexRouter.js";
// indexStore.jsの読み込みモジュールでiOS13系でエラーが出るため、ロードしない
import store from './store/indexStore'
import vueConfig from './vue.config.js';
// Vueで使用するHTTPクライアントモジュールの読み込み
import axios from 'axios';
// Setup VeeValidate
import {setupVeeValidate} from './plugin/veeValidate.js';
setupVeeValidate(Vue);
/**
 * コンポーネントの登録
 */
import {VApp} from 'vuetify/lib';
import VbHeader from "./components/organisms/vb-header.vue";
import VbFooter from "./components/organisms/vb-footer.vue";

window.VueConfig = vueConfig;
window.axios = axios;

/**
 * カスタムディレクティブ
 */
// v-showではdisplay:noneしかできないため、visibility: hiddenを使えるようにする
// v-visible=(条件)で使用する
Vue.directive('visible', function (el, binding) {
    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

// hiddenから取得したcustomerId
const bdCodeElement = document.getElementById('bd_code1');
Vue.prototype.$customerId = bdCodeElement ? bdCodeElement.value : null;

new Vue({
    el: '#vb-app-header',
    router,
    vuetify,
    store,
    components: {
        VApp,
        VbHeader,
    },
});
new Vue({
    el: '#vb-app-footer',
    router,
    vuetify,
    components: {
        VApp,
        VbFooter,
    },
});